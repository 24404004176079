import React, { useEffect, useState } from "react";
import axios from "axios";
import Doughnutplot from "../components/graph/doughnut";
import Barplot from "../components/graph/bar";
import Mapcomponent from "../components/map/map";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "@mui/x-date-pickers";
import Button from "../components/ui-patterns/Button";
import SimpleLineChart from "src/components/lineCharts/Line";
import LoginPage from "./login";
const appDownloadsData = {
  labels: ["Tranche 3", "Tranche 4"],
  datasets: [
    {
      label: "App Downloads",
      data: [1000, 5000],
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    }, 
    {
      label: "Autopay Setup",
      data: [100, 2000],
      fill: false,
      borderColor: "rgb(75, 112, 192)",
      tension: 0.1,
    }
  ]
}

const onChainMetricsData = {
  labels: ["Tranche 2", "Tranche 3", "Tranche 4"],
  datasets: [
    {
      label: "New Users",
      data: [100, 1000, 10000],
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    }, 
    {
      label: "Trading Volume",
      data: [100000, 1000000, 5000000],
      fill: false,
      borderColor: "rgb(75, 112, 192)",
      tension: 0.1,
    },
    {
      label: "Trading Transactions",
      data: [500, 5000, 25000],
      fill: false,
      borderColor: "rgb(75, 112, 132)",
      tension: 0.1,
    }
  ]

}

const offChainMetrics = {
  labels: ["Tranche 2", "Tranche 3", "Tranche 4"],
  datasets: [
    {
      label: "Discord Members",
      data: [2000, 5000, 10000],
      fill: false,
      borderColor: "rgb(75, 192, 192)",
      tension: 0.1,
    }, 
    {
      label: "Trading Volume",
      data: [5000, 10000, 20000],
      fill: false,
      borderColor: "rgb(75, 112, 124)",
      tension: 0.1,
    },
    {
      label: "Telegram Members",
      data: [500, 2000, 5000],
      fill: false,
      borderColor: "rgb(75, 112, 192)",
      tension: 0.1,
    }
  ]
}
export default function PolygonAnalytics() {
  const [isAuth, setIsAuth] = useState(false);
  const handleLogout = () => {
    // localStorage.removeItem("isLoggedIn");
    // window.location.reload();
    setIsAuth(false);
  };

  const [fromDate, setFromDate] = useState('Wed, 31 Jan 2024 18:30:00 GMT');
  const [toDate, setToDate] = useState(null);
  

  const handleToDateChange = (date: any) => {
    setToDate(date);
  };

  const [data, setData] = useState<any>({
    users: 0,
    totalUsers: 0,
    usersViaMetamask: 0,
    totalTradingVolume: 0,
    totalTransactions: 0,
    numberOfBuyTx: 0,
    numberOfMultiWithdrawTx: 0,
    numberOfReserveWithdrawTx: 0,
    numberOfInternalSwap: 0,
    buyVolume: 0,
    multiWithdrawVolume: 0,
    reserveWithdrawVolume: 0,
    internalSwapVolume: 0,
    totalVolumeLocked: 0,
    totalCAInitiated: 0,
    totalBetaCodeRedemption: 0,
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api.joinclamp.com/v1-1/dashboard/stats",
        {
          params: {
            fromDate: 'Wed, 31 Jan 2024 18:30:00 GMT',
            toDate: toDate ? toDate : new Date().toISOString(),
          },
        }
      );

      const totalBetaCodeRedemption = await axios.get(
        "https://api.joinclamp.com/v1-1/beta/redeemed"
      );
      response.data.data.totalBetaCodeRedemption =
        totalBetaCodeRedemption.data.data.totalRedeemed;

      const old: any = {
        buyVolume: 0,
        internalSwapVolume: 0,
        multiWithdrawVolume: 0,
        totalCAInitiated: 0,
        totalTradingVolume: 0,
        totalTransactions: 0,
        numberOfBuyTx: 0,
        numberOfInternalSwap: 0,
        numberOfMultiWithdrawTx: 0,
        numberOfReserveWithdrawTx: 0,
        reserveWithdrawVolume: 0,
        totalVolumeLocked: 0,
        uniqueUsers: 0,
        usersViaMetamask: 0,
        totalHits: 0,
        numberOfUniqueQuizUsers: 0,
        numberOfUniqueCalculatorUsers: 0,
        numberOfReferredByClamp: 0,
        numberOfReferredByOthers: 0,
        peopleWhoRefferedCount: 0,
        totalBetaCodeRedemption: 0,
        numberOfSingleSwapTx: 0,
        singleSwapVolume: 0,
      };
      const final: any = {};

      for (const key in old) {
        final[key] = old[key] + response.data.data[key];
      }
      return final;
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  const [userdata, setUserData] = useState<any>(null);
  const handleFetchStatistics = async () => {
    fetchDataStat();
    const statsData = await fetchData();
    setData(statsData);
  };

  const fetchDataStat = async () => {
    try {

      const apiBaseUrl = "https://api.joinclamp.com/v1-1/dashboard";
      const [dashboardResponse, usersByCountryResponse] = await Promise.all([axios.get(`${apiBaseUrl}/getdata`, {
        params: {
          fromDate,
          toDate: toDate || new Date().toISOString(), 
        },
      }),axios.get(`${apiBaseUrl}/user/country`)]);
      
      // @ts-ignore
      const usersByCountryMap = usersByCountryResponse.data.data.countries.reduce((acc, country, index) => {
        acc[country] = usersByCountryResponse.data.data.values[index];
        return acc;
        }, {})

      // @ts-ignore
      const countryUserCounts = dashboardResponse.data.data.countries.map(country => usersByCountryMap[country] || 0);
  
      const userData = {
        countries: dashboardResponse.data.data.countries,
        values: dashboardResponse.data.data.values,
        users: dashboardResponse.data.data.users,
        countryByUsers: countryUserCounts,
        mapData: dashboardResponse.data.data.mapData,
      };
  

      setUserData(userData);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  useEffect(() => {

    handleFetchStatistics();
    
    
  }, []);

  const [autoFetchEnabled, setAutoFetchEnabled] = useState(false);
  const toggleAutoFetch = () => {
    setAutoFetchEnabled(!autoFetchEnabled);
  };

  useEffect(() => {
    let intervalId: any = null;

    if (autoFetchEnabled) {
      intervalId = setInterval(() => {
        handleFetchStatistics();
      }, 60000); // Fetch data every 1 minute (60,000 milliseconds)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoFetchEnabled]);

  return (
    <>
    {
      userdata && isAuth && (<div className="flex flex-col">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          alignSelf: "auto",
        }}
      >
       {userdata && <Mapcomponent mapData={userdata.mapData} />}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "30px",
          marginRight: "30px",
        }}
        className="graph"
      >
        {/* <div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
         {userdata && <Doughnutplot countryKeys={userdata.countries} countryValues={userdata.values}/>}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "50%",
            }}
          >
            {userdata && (
              <>
                <table style={{ width: "50%" }}>
                  <tr>
                    <th>Country</th>
                    <th style={{ padding: "10px" }}>Hits</th>
                    <th style={{ padding: "10px" }}>Visitors</th>
                    <th>Users</th>
                  </tr>
                  {userdata.countries
                    ?.slice(0, Math.floor(userdata.countries.length / 2))
                    .map((item: any, index: any) => (
                      <tr>
                        <td>{item}</td>
                        <td>{userdata.values[index]}</td>
                        <td>{userdata.users[index]}</td>
                        <td>{userdata.countryByUsers[index]}</td>
                      </tr>
                    ))}
                </table>

                <table style={{ width: "50%" }}>
                  <tr>
                    <th>Country</th>
                    <th style={{ padding: "10px" }}>Hits</th>
                    <th style={{ padding: "10px" }}>Visitors</th>
                    <th>Users</th>
                    
                  </tr>
                  {userdata.countries
                    ?.slice(Math.floor(userdata.countries.length / 2))
                    .map((item: any, index: any) => {
                      const adjustedIndex =
                        index + Math.floor(userdata.countries.length / 2);
                      return (
                        <tr>
                          <td>{item}</td>
                          <td>{userdata.values[adjustedIndex]}</td>
                          <td>{userdata.users[adjustedIndex]}</td>
                          <td>{userdata.countryByUsers[adjustedIndex]}</td>
                        </tr>
                      );
                    })}
                </table>
              </>
            )}
          </div>
        </div>

        {/* </div> */}

        {userdata && <Barplot countryKeys={userdata.countries} countryValues={userdata.values} />}
      </div>

      <div
        className="stats"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "3%",
            marginTop: "3px",
          }}
        >
         
          <div
            style={{ display: "flex", flexDirection: "column", width: "40%" }}
          >
            <label>To </label>
            <DatePicker value={toDate} onChange={handleToDateChange} />
          </div>
          {/* <button onClick={handleFetchStatistics} disabled={!fromDate || !toDate}>
          Fetch Statistics
        </button> */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "18px",
            }}
          >
            <Button
              variant="outline"
              onClick={handleFetchStatistics}
              disabled={!toDate}
            >
              Fetch Statistics
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <label>
              Auto Fetch Data:
              <input
                type="checkbox"
                checked={autoFetchEnabled}
                onChange={toggleAutoFetch}
              />
            </label>
          </div>
        </div>
        <div
        className="mx-3"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr)",
            gridGap: "1rem",
            marginTop: "1rem",
          }}
        >
           {
            [
              [
                "Total Hits (Home Page Reloads)" , data?.totalHits,
              ],
              [
                "Total Unique Visitors (As Per IP)" , data?.uniqueUsers,
              ],
              [
                "Users Connected To Website (Using Metamask)" , data?.usersViaMetamask,
              ],
              [
                "Total CA generated" , data?.totalCAInitiated,
              ],
              [
                "Total Trading Volume" , '$' + data?.totalTradingVolume.toFixed(2),
              ],
              [
                "Buy Volume" , '$' + data?.buyVolume.toFixed(2),
              ],
              [
                "Reserve Withdraw Volume" , '$' + data?.reserveWithdrawVolume.toFixed(2),
              ],
              [
                "Internal Swap Volume" , '$' + data?.internalSwapVolume.toFixed(2),
              ],
              [
                "Single Swap Volume" , '$' + data?.singleSwapVolume?.toFixed(2),
              ],
              [
                "Total Volume Locked" , '$' + data?.totalVolumeLocked.toFixed(2),
              ],
              [
                "Total Transactions" , data?.totalTransactions,
              ],
              [
                "Number of Buy Transactions" , data?.numberOfBuyTx,
              ],
              [
                "Number of Reserve Withdraw Transactions" , data?.numberOfReserveWithdrawTx,
              ],
              [
                "Number of Internal Swaps" , data?.numberOfInternalSwap,
              ],
              [
                "Number of Single Swaps" , data?.numberOfSingleSwapTx,
              ],
              [
                "Unique Quiz Users" , data?.numberOfUniqueQuizUsers,
              ],
              [
                "Unique Calculator Users" , data?.numberOfUniqueCalculatorUsers,
              ],
              [
                "Users Referred By Clamp" , data?.numberOfReferredByClamp,
              ],
              [
                "Users Referred By Others" , data?.numberOfReferredByOthers,
              ],
              [
                "Users using referrals" , data?.peopleWhoRefferedCount,
              ],
              [
                "Total Beta Code Redemption" , data?.totalBetaCodeRedemption,
              ],
            ].map((item) => {
              // extract key and value from each item
            
              return <>
              <div
            className="card h-48 flex flex-col justify-center items-center"
            style={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              padding: "1rem",
              backgroundColor: "#f5f5f5",
            }}
          >
            <h3>{item[0]}: </h3>
            <p style={{ fontSize: "1.5rem" }}>{item[1]}</p>
          </div>
          </>
            })
          }
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      ></div>
      <div className="w-full flex flex-row justify-center items-center">

<SimpleLineChart 
  data={appDownloadsData}
  options={{
    scales: {
      y: {
        display: true,
        name: 'Number of Downloads',
      },
      x: {
        display: true,
        name: 'Tranches'
      }
    }
  }}
  />
<SimpleLineChart 
  data={onChainMetricsData}
  options={{
    scales: {
      y: {
        type: 'logarithmic',
        min: 100,
        max: 100000000,
        display: true,
        name: 'Number of Users',
      },
      x: {
        display: true,
        name: 'Tranches'
      }
    }
  }}
  />
  </div>
  <div className="w-1/2">
  <SimpleLineChart
  data={offChainMetrics}
  options={{
    scales: {
      y: {
        display: true,
        name: 'Number of Users',
      },
      x: {
        display: true,
        name: 'Tranches'
      }
    }
  }}
  />
  </div>
      <Button
        variant="primary"
        onClick={handleLogout}
        style={{ marginBottom: "30px" }}
      >
        Logout
      </Button>
    </div>)
    }
    {!isAuth && <LoginPage setIsAuth={setIsAuth}/>}
    </>
  );
}
