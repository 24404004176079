let backend_data;

const doughnutlabels = [];
const doughnutdata = [];
const barlabels = [];
const bardata = [];
const percentmap = new Map();
const doughnutmap = new Map();
const barmap = new Map()
const Doughnut_assembleData = async(countryKeys, values) => {

    console.log(countryKeys, values, 'doughnut')
    for (let i = 0; i < countryKeys.length; i++) {
        doughnutmap.set(countryKeys[i], values[i]);
    }

    const sum = Array.from(doughnutmap.values()).reduce((acc, val) => acc + val, 0);

    //@ts-ignore
    for (let [key, value] of doughnutmap) {
        percentmap.set(key, (doughnutmap.get(key) / sum) * 100);
    }
    //@ts-ignore
    for (let [key, value] of percentmap) {
        doughnutlabels.push(key);
        doughnutdata.push(value);
    }


};

const bar_assembledata = async(countryKeys, values) => {

    for (let i = 0; i < countryKeys.length; i++) {
        barmap.set(countryKeys[i], values[i]);
    }


    for (let [key, value] of barmap) {
        barlabels.push(key);
        bardata.push(value);
    }
}

const bar_reqdata = {
    labels: barlabels,
    datasets: [{
        label: "Poll",
        data: bardata,
        backgroundColor: ["#0074D9", "#FF4136", "#2ECC40", "#FF851B", "#7FDBFF", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA"],
        borderColor: ["#6b32a8"],
    }]
}


const bar_options = {
    scales: {
        y: {
            // beginAtZero: true,
            type: 'logarithmic',
            min: 1,

            // base: 5
        },
    },
    indexAxis: "x",
    barThickness: 50, 
};
const doughnut_reqdata = {
    labels: doughnutlabels,
    datasets: [{
        label: "Poll",
        data: doughnutdata,
        backgroundColor: ["#0074D9", "#FF4136", "#2ECC40", "#FF851B", "#7FDBFF", "#B10DC9", "#FFDC00", "#001f3f", "#39CCCC", "#01FF70", "#85144b", "#F012BE", "#3D9970", "#111111", "#AAAAAA"],
        borderColor: ["#6b32a8"],
    }, ],
};

const doughnut_options = {};

export default { doughnut_reqdata, doughnut_options, bar_reqdata, bar_options, backend_data, Doughnut_assembleData, bar_assembledata };