import "mapbox-gl/dist/mapbox-gl.css";
import Map, {
  Marker,
  NavigationControl,
  Popup,
  FullscreenControl,
  GeolocateControl,
} from "react-map-gl";
import { useEffect, useState } from "react";
import axios from 'axios'



const apikey = "pk.eyJ1IjoiYWJoaXhoZWsiLCJhIjoiY2xraGNibWN4MDgwajNlbnplMnA4ZGQ3dyJ9.PrHF3nWP3V343-JK-F59zw";

// Function to filter unique IP addresses
function getUniqueIPData(inputData) {
  const uniqueIPs = {};
  const uniqueData = inputData.data.filter((item) => {
    if (!uniqueIPs[item.ipadd]) {
      uniqueIPs[item.ipadd] = true;
      return true;
    }
    return false;
  });

  return {
    ...inputData,
    data: uniqueData,
  };
}
 function Mapcomponent({mapData}) {
  const [userdata,setData] = useState([])
  useEffect(() => {
        mapData = mapData.filter((item) => item.latitude && item.longitude);
        setData(mapData);
  }, []);


  return (
    <>
    { 
    userdata.length > 0 &&
   <div style={{display:"flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBlock: '30px', width: '100%'}}>
   
  <h1 style={{fontFamily: 'sans-serif'}}>Visitor Demographic</h1>
      <Map
        mapboxAccessToken={apikey}
        style={{
          width: "95%",
          height: "500px",
          borderRadius: "15px",
          
        }}
        initialViewState={{
          longitude: 75,
          latitude:22,
        }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        >
        { userdata.map((element, key)=>{
          return <Marker longitude={Number(element?.longitude)} latitude={Number(element?.latitude)} key={key}></Marker>
        })}
  
        <NavigationControl position="bottom-right" />
        <FullscreenControl />

        <GeolocateControl />
      </Map>
    
    </div>
 }</>
  );
}



export default Mapcomponent;