import React,{useEffect} from "react";
import axios from 'axios'
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import userdata from './api'


function BarChart({countryKeys, countryValues}) {
  const [data,setData] = React.useState(null)
    useEffect(()=>{
      if(!countryKeys?.length) return;
      (async () => {
        await userdata.bar_assembledata(countryKeys, countryValues);
        setData(true);
      })();
        
          
           

          },[countryKeys,countryValues])

  return (
  <>
  {
    data &&
  <Bar
  style={
    {width:'10px',height:"10px"}
  } data={userdata?.bar_reqdata} options={userdata?.bar_options} />
}
  </>
  );
}

export default BarChart;