import {useEffect, useState} from "react";

const BetaCodesValidation = ({setIsAuth} : any) => {
    const [data, setData] = useState({
        activeUsers: 0,
        dormantUsers: 0,
        hour24: 0,
        hour48: 0,
        hour96: 0
    })

    // const getData = () =>  fetch('https://api.joinclamp.com/v1-1/beta/unshared', {
    //     method: "GET",
    // }).then(res => res.json())
    //     .then(data => {

    //         setData(data.data)  
    //     })

    const getBetaActivityData = () => fetch('https://api.joinclamp.com/v1-1/dashboard/beta/stats').then(res => res.json()).then(data => {
        setData(data.data)
    }
    )

    useEffect(() => {
        getBetaActivityData();
    }, []);

    const handleLogout = () => {
        setIsAuth(false)
    }

    // const handleAccept = (e: any, index: number) => {
    //     e.preventDefault()
    //     const item = data[index]
    //     try { 
    //         fetch(`https://api.joinclamp.com/v1-1/beta/share`, {
    //             method: "PATCH",
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 code: item['code'],
    //                 rejected: false
    //             })
    //         }).then(res => res.json())
    //             .then(data => {

    //                 getData()
    //             })
    //     } catch (e) {
    //         console.error(e)
    //     }
    // }

    // const handleReject = (e: any, index: number) => {
    //     e.preventDefault()
    //     const item = data[index]
    //     try {
    //         fetch(`https://api.joinclamp.com/v1-1/beta/share`, {
    //             method: "PATCH",
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 code: item['code'],
    //                 rejected: true
    //             })
    //         }).then(res => res.json())
    //             .then(data => {

    //                 getData()
    //             })
    //     } catch (e) {
    //         console.error(e)
    //     }
    // }

    return (
        // <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
        //     <h1>Beta Codes Valdidation</h1>
        //     <button onClick={handleLogout}>Logout</button>
        //     <table className="" style={{width: '90%', marginTop: '10px'}}>
        //         <tr>
        //             <th>Date</th>
        //             <th>Time</th>
        //             <th>Name</th>
        //             <th>Email</th>
        //             <th>Code</th>
        //         </tr>
        //         {!data.length ? <p>No New Items</p> : data.map((item, index) => {
        //             return (
        //                 <tr key={index}>
        //                     {/* <td><a href={`https://polygonscan.com/address/${item[0]}`} target={"_blank"} rel={"noreferrer"}>{item[0].slice(0,6)}...{item[0].slice(-4)}</a></td> */}
        //                     <td>{item['date']}</td>
        //                     <td>{item['time']}</td>
        //                     <td>{item['name']}</td>
        //                     <td>{item['email']}</td>
        //                     <td>{item['code']}</td>
        //                     {/* <td><a href={item[6]} target={"_blank"} rel={"noreferrer"}>{item[6]}</a></td> */}
        //                     <td>
        //                         <button onClick={(e) => handleAccept(e, index)} style={{backgroundColor: "#1abe43", color: "white", border: "none", height: "35px", fontWeight: "bold", cursor: "pointer"}}>Accept</button>
        //                     </td>
        //                     <td>
        //                         <button onClick={(e) => handleReject(e, index)} style={{backgroundColor: "#da0909", color: "white", border: "none", height: "35px", fontWeight: "bold", cursor: "pointer"}}>Reject</button>
        //                     </td>
        //                 </tr>
        //             )
        //         })}
        //     </table>
        // </div>
       <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            <h1>Beta Codes Valdidation</h1>
            <button onClick={handleLogout}>Logout</button>
            <table>
                <tr>
                    <th>Active Users</th>
                    <th>Dormant Users</th>
                    <th>24 Hours</th>
                    <th>48 Hours</th>
                    <th>96 Hours</th>
                </tr>

                        <tr >
                            <td>{data.activeUsers}</td>
                            <td>{data.dormantUsers}</td>
                            <td>{data.hour24}</td>
                            <td>{data.hour48}</td>
                            <td>{data.hour96}</td>
                        </tr>
                   
            </table>
        </div>
    )
}

export default BetaCodesValidation;