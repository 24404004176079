import React, { useEffect, useState } from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import Button from "../components/ui-patterns/Button";
const provider = new GoogleAuthProvider();

const allowedEmails = [
  "abhishek@joinclamp.com",
  "tejas.warambhe@joinclamp.com",
  "suhel.kapadia@joinclamp.com"
]
function LoginPage({setIsAuth}: any) {
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");

  const handleLogin = () => {
    // if (username === "admin" && password === "admin") {
    //   localStorage.setItem("isLoggedIn", "true");
    //   window.location.reload();
    // } else {
    //   alert("Invalid username or password");
    // }
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential: any = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user: any = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        setIsAuth(allowedEmails.includes(user.email));
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        setIsAuth(false);
      });
  };

  return (
    <div className="flex flex-row justify-center items-center h-screen">

      <Button onClick={handleLogin} className="">Login With Google</Button>
    </div>
  
  );
}

export default LoginPage;
