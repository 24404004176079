import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import PolygonAnalytics from './pages/PolygonAnalytics';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter([
    {
      path: "/",
      element: <App /> ,
    },
    {
        path: "/polygon",
        element: <PolygonAnalytics /> ,
      },
  ]);

root.render(
<React.StrictMode>
<LocalizationProvider dateAdapter={AdapterDayjs}>
    <RouterProvider router={router} />
</LocalizationProvider>
  </React.StrictMode>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
