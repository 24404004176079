import React, { useState, ChangeEvent, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Grid,
  Alert,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  IconButton
} from '@mui/material';


interface WhitelistReferralsProps {
  setIsAuth: (isAuth: boolean) => void;
}

interface FormState {
  name: string;
  walletContractAddress: string;
  referralCode: string;
}

interface FormErrors {
  name?: string;
  walletContractAddress?: string;
  referralCode?: string;
}

interface WhitelistedUser {
  id: string; // Added id for unique identification
  userName: string;
  walletContractAddress: string;
  referralCode: string;
  totalReferred: number;
}

// Assume these functions fetch data from your API
const fetchWhitelistedUsers = async (): Promise<WhitelistedUser[]> => {
  // This is a mock implementation. Replace with actual API call.
//   await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate network delay
//   return [
//     { id: "1", userName: "Alice", walletContractAddress: "0x1234...5678", referralCode: "ALICE001", totalReferred: 5 },
//     { id: "2", userName: "Bob", walletContractAddress: "0xabcd...efgh", referralCode: "BOB002", totalReferred: 3 },
//     { id: "3", userName: "Charlie", walletContractAddress: "0x9876...5432", referralCode: "CHARLIE003" , totalReferred: 2 },
//   ];
    const response = await fetch('https://api.joinclamp.com/v1-1/dashboard/referral/whitelist')
    const data = await response.json();
    console.log(data);
    const finalArr = data.data.map((item: any, idx: number) => {
        return {
            id: idx,
            userName: item.userName,
            walletContractAddress: item.walletContractAddress,
            referralCode: item.referralCode,
            totalReferred: item.totalReferred
        }
    }
    )
    return finalArr;
};

const removeWhitelistedUser = async ({
    walletContractAddress,
} : any): Promise<void> => {
  // This is a mock implementation. Replace with actual API call.
//   await new Promise(resolve => setTimeout(resolve, 1000)); // Simulate network delay
    const removeUser = await fetch('https://api.joinclamp.com/v1-1/dashboard/referral/whitelist/' + walletContractAddress, {
        method: 'DELETE',
    });
    const data = await removeUser.json();
    console.log(data);
    console.log(`User with ID ${walletContractAddress} removed from whitelist`);

//   console.log(`User with ID ${userId} removed from whitelist`);
};

export default function WhitelistReferrals({ setIsAuth }: WhitelistReferralsProps) {
  const [state, setState] = useState<FormState>({
    name: "",
    walletContractAddress: "",
    referralCode: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [whitelistedUsers, setWhitelistedUsers] = useState<WhitelistedUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [removeLoading, setRemoveLoading] = useState<string | null>(null);

  useEffect(() => {
    loadWhitelistedUsers();
  }, []);

  const loadWhitelistedUsers = async () => {
    setIsLoading(true);
    try {
      const users = await fetchWhitelistedUsers();
      setWhitelistedUsers(users);
    } catch (error) {
      console.error("Failed to fetch whitelisted users:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = (): boolean => {
    let isValid = true;
    let newErrors: FormErrors = {};

    if (!state.name.trim()) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!state.walletContractAddress.trim()) {
      newErrors.walletContractAddress = "Wallet Contract Address is required";
      isValid = false;
    } else if (!/^0x[a-fA-F0-9]{40}$/.test(state.walletContractAddress)) {
      newErrors.walletContractAddress = "Invalid Ethereum address format";
      isValid = false;
    }

    if (!state.referralCode.trim()) {
      newErrors.referralCode = "Referral Code is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const whitelistReferrals = async () => {
    if (validateForm()) {
      // Proceed with whitelisting logic
      console.log("Form is valid, proceed with whitelisting", state);
      const response = await fetch('https://api.joinclamp.com/v1-1/dashboard/referral/whitelist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userName: state.name,
            walletContractAddress: state.walletContractAddress,
            referralCode: state.referralCode,
            enabled: true
        }),
      });
        const data = await response.json();
        console.log(data);
      // After successful whitelisting, refresh the list
      await loadWhitelistedUsers();
    }
  };

  const handleRemoveUser = async (userId: string) => {
    setRemoveLoading(userId);
    try {
        const userData = whitelistedUsers.find(user => user.id === userId);
    if(userData)
        await removeWhitelistedUser(userData);
      await loadWhitelistedUsers(); // Refresh the list after removal
    } catch (error) {
      console.error("Failed to remove user:", error);
      // You might want to show an error message to the user here
    } finally {
      setRemoveLoading(null);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Whitelist Referrals
        </Typography>

        <Box component="form" noValidate autoComplete="off" sx={{ mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Name"
                value={state.name}
                onChange={handleInputChange}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="walletContractAddress"
                name="walletContractAddress"
                label="Wallet Contract Address"
                value={state.walletContractAddress}
                onChange={handleInputChange}
                error={!!errors.walletContractAddress}
                helperText={errors.walletContractAddress}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                id="referralCode"
                name="referralCode"
                label="Referral Code"
                value={state.referralCode}
                onChange={handleInputChange}
                error={!!errors.referralCode}
                helperText={errors.referralCode}
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={whitelistReferrals}
              size="large"
            >
              Whitelist User
            </Button>
          </Box>

          {Object.keys(errors).length > 0 && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Please correct the errors before submitting.
            </Alert>
          )}
        </Box>

        <Typography variant="h5" component="h2" gutterBottom>
          Whitelisted Users
        </Typography>

        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Wallet Contract Address</TableCell>
                  <TableCell> Total Referrered</TableCell>
                  <TableCell>Referral Code</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {whitelistedUsers.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.userName}</TableCell>
                    <TableCell onClick={() => {
                        window.open(`https://polygonscan.com/address/${user.walletContractAddress}`, '_blank')
                    }} className={'underline'}>{user.walletContractAddress.slice(0, 6) + '...' + user.walletContractAddress.slice(37)}</TableCell>
                    <TableCell>{user.totalReferred}</TableCell>
                    <TableCell>{user.referralCode}</TableCell>
                    <TableCell>
                      <IconButton 
                        onClick={() => handleRemoveUser(user.id)}
                        disabled={removeLoading === user.id}
                      >
                        {removeLoading === user.id ? (
                          <CircularProgress size={24} />
                        ) : (
                            <Button variant="contained" color="secondary">
                                Remove
                            </Button>
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Container>
  );
}