import {useEffect, useState} from "react";
import Button from "src/components/ui-patterns/Button";

const Validation = ({setIsAuth}) => {
    const [data, setData] = useState([])

    const getData = () =>  fetch('https://api.joinclamp.com/v1-1/rewards/social', {
        method: "GET",
    }).then(res => res.json())
        .then(data => {
            setData(data.data)
        })

    useEffect(() => {
        getData()
    }, []);

    const handleLogout = () => {
        setIsAuth(false)
    }

    const handleAccept = (e, index) => {
        e.preventDefault()
        const item = data[index]
        try {
            fetch(`https://api.joinclamp.com/v1-1/rewards/social/${index}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userAddress: item[0],
                    rewardAmount: item[4],
                    rewardType: item[3],
                    result: "accepted"
                })
            }).then(res => res.json())
                .then(data => {
                    getData()
                })
        } catch (e) {
            console.error(e)
        }
    }

    const handleReject = (e, index) => {
        e.preventDefault()
        const item = data[index]
        try {
            fetch(`https://api.joinclamp.com/v1-1/rewards/social/${index}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    userAddress: item[0],
                    rewardAmount: item[4],
                    rewardType: item[3],
                    result: "rejected"
                })
            }).then(res => res.json())
                .then(data => {
                    getData()
                })
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div>
            <h1>Validation</h1>
            <Button onClick={handleLogout}>Logout</Button>
            <table>
                <tr>
                    <th>User Address</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Claim</th>
                    <th>Amount</th>
                    <th>User ID</th>
                    <th>URL</th>
                    <th>Accept</th>
                    <th>Reject</th>
                </tr>
                {!data.length ? <p>No New Items</p> : data.map((item, index) => {
                    return (
                        <tr key={index}>
                            <td><a href={`https://polygonscan.com/address/${item[0]}`} target={"_blank"} rel={"noreferrer"}>{item[0].slice(0,6)}...{item[0].slice(-4)}</a></td>
                            <td>{item[1]}</td>
                            <td>{item[2]}</td>
                            <td>{item[3]}</td>
                            <td>{item[4]}</td>
                            <td>{item[5]}</td>
                            <td><a href={item[6]} target={"_blank"} rel={"noreferrer"}>{item[6]}</a></td>
                            <td>
                                <button onClick={(e) => handleAccept(e, index)} style={{backgroundColor: "#1abe43", color: "white", border: "none", height: "35px", fontWeight: "bold", cursor: "pointer"}}>Accept</button>
                            </td>
                            <td>
                                <button onClick={(e) => handleReject(e, index)} style={{backgroundColor: "#da0909", color: "white", border: "none", height: "35px", fontWeight: "bold", cursor: "pointer"}}>Reject</button>
                            </td>
                        </tr>
                    )
                })}
            </table>
        </div>
    )
}

export default Validation