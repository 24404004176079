// firebase.js

import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAbXVHYaQMlB1H8aosDAv_Q6Pm6JULAywY",
    authDomain: "clamp-analytics.firebaseapp.com",
    projectId: "clamp-analytics",
    storageBucket: "clamp-analytics.appspot.com",
    messagingSenderId: "76785832594",
    appId: "1:76785832594:web:9eec35f3ff252f745ccc4b"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;