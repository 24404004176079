import React from "react";
import { Line } from "react-chartjs-2";

const SimpleLineChart = ({ data, options, title } : { data : {
  labels: string[],
  datasets: {
    label: string,
    data: number[],
    fill: boolean,
    borderColor: string,
    tension: number,
  }[],
  }, options?: any, title?: string}) => {


  return (
    <div className="m-10 w-full">
      <h2>{title}</h2>
      <Line data={data} options={options}/>
    </div>
  );
};

export default SimpleLineChart;

