import {useEffect, useState} from 'react';
import './App.css';
import Charts from './pages/charts';
import Login from './pages/login';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import firebaseApp from './firebase';
import Validation from "./pages/validation";
import BetaCodesValidation from './pages/BetaCodesValidation';
import PolygonAnalytics from './pages/PolygonAnalytics';
import Button from './components/ui-patterns/Button/index';
import WhitelistReferrals from './pages/WhitelistReferrals';




function App() {
    // const isLoggedIn = localStorage.getItem("isLoggedIn");
    const [isAuth, setIsAuth] = useState(false);
    const [chart, setChart] = useState(false)
    const [validate, setValidate] = useState(false)
    const [betaCodes, setBetaCodes] = useState(false);
    const [polygonAnalytics, setPolygonAnalytics] = useState(false);
    const [whitelist, setWhitelist] = useState(false);
    useEffect(() => {
        (async () => {
            const init = firebaseApp
        })();
    }, []);

    
    return (
        <>

                {/* <Route path="/login" component={Login} /> */}
            <div className="App">
                <div className={`${!(chart || validate || betaCodes || whitelist || polygonAnalytics) ? 'flex flex-row justify-center items-center h-screen' : 'flex flex-row justify-center'}`}>

                {!isAuth && <Login setIsAuth={setIsAuth}/>}
               
                {isAuth && <>
                    <Button onClick={() => {
                        setChart(true)
                        setValidate(false)
                        setBetaCodes(false)
                        setPolygonAnalytics(false)
                        setWhitelist(false)                    
                    }
                        
                     } className="mx-10">Chart</Button>
                    <Button onClick={() => {
                        setChart(false)
                        setValidate(true)
                        setBetaCodes(false)
                        setWhitelist(false)
                        setPolygonAnalytics(false)
                    }}
                    className="mx-10">Validate</Button>
                    <Button onClick={() => {
                        setChart(false)
                        setValidate(false)
                        setWhitelist(false)
                        setBetaCodes(true)
                        setPolygonAnalytics(false)
                    }}className="mx-10">BetaCodes</Button>
                    <Button onClick={() => {
                        setChart(false)
                        setValidate(false)
                        setWhitelist(true)
                        setBetaCodes(false)
                        setPolygonAnalytics(false)
                    }}className="mx-10">Whitelist Referral</Button>
                    {/* <Button onClick={() => {
                        setChart(false)
                        setValidate(false)
                        setBetaCodes(false)
                        setPolygonAnalytics(true)
                    }}className="mx-10">Polygon</Button> */}
                    
                </>}
                </div>
                {isAuth && chart && <Charts setIsAuth={setIsAuth}/>}
                {isAuth && validate && <Validation setIsAuth={setIsAuth}/>}
                {isAuth && betaCodes && <BetaCodesValidation setIsAuth={setIsAuth}/>}
                {isAuth && whitelist && <WhitelistReferrals setIsAuth={setIsAuth}/>}
                {/* {isAuth &&   && <PolygonAnalytics setIsAuth={setIsAuth} />} */}
                
            </div>

            
        </>
    );
}

export default App;
