import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend,  } from "chart.js";
import React, { useEffect } from "react";


import { Doughnut } from "react-chartjs-2";
import userdata from './api'



ChartJS.register(ArcElement, Tooltip, Legend);

 const Doughnutplot = ({ countryKeys, countryValues}) => {
  const [data,setData] = React.useState(null)
 useEffect(()=>{

  if(!countryKeys?.length) return;
     (async () => {
      await userdata.Doughnut_assembleData(countryKeys, countryValues).then(() => {
        setData(true);
       })
     })();
 
  },[])
  
    return (
      <>
      {
        data &&
      <div style={{width:'30%', marginBottom: '30px'}} className="chart">
      <Doughnut style={{width:50}} data={userdata?.doughnut_reqdata} options={userdata?.doughnut_options} />
      
      </div>
      }
      </>
    )
  }
  
  export default Doughnutplot;